import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReCAPTCHA from "react-google-recaptcha"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { StaticQuery, graphql } from "gatsby"
// Components
import CheckMark from "./valid"
// Styles
import styles from "./scss/contact.module.scss"

class NewsLetter extends Component {
  state = {
    email: "",
    name: "",
    msg: null,
    isVerified: false,
  }

  onChange = response => {
    this.setState({ isVerified: true })
  }
  handleSubmit = async evt => {
    evt.preventDefault()

    if (this.state.isVerified) {
      const result = await addToMailchimp(this.state.email.value, {
        NAME: this.state.name.value,
      })

      this.setState({ msg: result.msg })
      setTimeout(() => {
        this.setState({ email: "", msg: null, name: "" })
      }, 3000)
    } else {
      this.setState({ msg: "Please verify you are human." })
      setTimeout(() => {
        this.setState({ msg: null })
      }, 3000)
    }
  }

  handleChange = evt => {
    const isValid = evt.target.checkValidity()
    this.setState({
      [evt.target.name]: {
        value: evt.target.value,
        valid: isValid,
      },
    })
  }
  render() {
    const style = {
      flexDirection: `column`,
    }
    return (
      <section
        id="subscribe"
        className="section"
        style={{
          backgroundImage: `url(${this.props.data.contentfulNewsLetterSection.image.file.url})`,
          backgroundSize: `cover`,
        }}
      >
        <form
          method="post"
          onSubmit={this.handleSubmit}
          className={styles.form}
          style={{
            backgroundColor: `rgba(255,255,255,1)`,
            padding: `25px`,
            maxWidth: `800px`,
          }}
        >
          <div className="banner">
            <h2 className="ribbon">
              <span className="top">&nbsp;</span>
              <span className="text">
                {this.props.data.contentfulNewsLetterSection.title}
              </span>
              <span className="bottom">&nbsp;</span>
            </h2>
          </div>
          <label htmlFor="email" style={{ textAlign: "center" }}>
            {documentToReactComponents(
              this.props.data.contentfulNewsLetterSection.body.json
            )}
          </label>
          <div className={styles.newsLetter}>
            <div role="group" style={style}>
              <label htmlFor="name" style={{ width: `100%` }}>
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={this.state.name.value || ""}
                onChange={this.handleChange}
              />

              <CheckMark value={this.state.name.valid} />
            </div>
            <div role="group" style={style}>
              <label htmlFor="email" style={{ width: `100%` }}>
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                value={this.state.email.value || ""}
                onChange={this.handleChange}
              />

              <CheckMark value={this.state.email.valid} />
            </div>
          </div>
          <ReCAPTCHA
            className={styles.recaptcha}
            sitekey="6Le-h7gUAAAAAOUAHYr3etE5i-jWNf5HmHGOIAFS"
            onChange={this.onChange}
          />
          <input type="submit" value="Submit" className={styles.signUp} />
          <span>{this.state.msg}</span>
        </form>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulNewsLetterSection {
          title
          body {
            json
          }
          image {
            description
            title
            file {
              url
            }
          }
        }
      }
    `}
    render={data => <NewsLetter data={data} {...props} />}
  />
)
