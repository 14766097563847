import React, { Component } from "react"
import { FaRegNewspaper } from "react-icons/fa"
// Styles
import styles from "./scss/sidebar.module.scss"

export default class sidebar extends Component {
  render() {
    return (
      <div className={styles.sidebar}>
        <FaRegNewspaper /> <a href="#subscribe">Subscribe </a>
      </div>
    )
  }
}
